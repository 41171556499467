// @flow
import React from 'react';
import styled from 'styled-components';
import {
  Carousel,
  GlossaryLink as G,
  Layout,
  withFrontMatter,
} from '../components';
import type { FrontMatter, ImageType } from '../utils/types';
import { graphql } from 'gatsby';
import { border } from '../utils/styles';

const StyledCarousel = styled(Carousel)`
  ${border};
`;

type Props = {
  frontMatter: FrontMatter,
  data: {
    tieEarnest01: ImageType,
    tieEarnest02: ImageType,
    tieEarnest03: ImageType,
    tieEarnest04: ImageType,
    tieEarnest05: ImageType,
    tieEarnest06: ImageType,
    tieEarnest07: ImageType,
  },
};

class Canyoneering_Earnest extends React.Component<Props> {
  render() {
    const { frontMatter, data } = this.props;

    return (
      <Layout frontMatter={frontMatter} toc>
        <h2>What is EARNEST?</h2>
        <p>
          An acronym to determine if an <G>anchor</G> is ideal. Let's go over
          what each letter means.
        </p>
        <h2>Equalized</h2>
        <p>
          The weight is equally distributed on each strand of <G>webbing</G>{' '}
          when weighted by the rappeller.
          {/* TODO: Add this sentence once "equalized" has its own page "This assumes that all anchors are equally solid."*/}
        </p>
        <p>
          For example, if you have two loops of webbing connected to a pair of{' '}
          <G>bolts</G> or around a large tree then each loop should hold about
          50% of the weight. If there are three loops of webbing around three
          anchors each should hold about a third of the weight.
        </p>
        {/* TODO: This goes on the "equalized" page if it ever exists */}
        {/*<h3>Focused rigging</h3>*/}
        {/*<p>*/}
        {/*  At some drops there are no bomber anchors and canyoneers have to use*/}
        {/*  multiple marginal anchors. Examples are a clump of small bushes or*/}
        {/*  multiple solution pockets. If one of the anchors is stronger than the*/}
        {/*  other, equalize the webbing in a specific direction so that the weight*/}
        {/*  distribution favors the stronger anchor.*/}
        {/*</p>*/}
        <h2>Angle</h2>
        <p>
          The angle between any two strands of webbing should be at most 90°
          (right angle). More than that produces a significant amount of force
          on the anchors. Here's a simple list of the angle between the widest
          two strands and how much force is applied. The assumption is that the
          two anchors and their <G>master point</G> create an Isosceles
          triangle.
        </p>
        <ul>
          <li>0°: Each anchor has 50% of the force applied to it</li>
          {/*<li>40°: Each anchor has 53% of the force applied to it</li>*/}
          <li>45°: Each anchor has 5k% of the force applied to it</li>
          <li>60°: Each anchor has 58% of the force applied to it</li>
          <li>90°: Each anchor has 71% of the force applied to it</li>
          <li>120°: Each anchor has 100% of the force applied to it</li>
          {/*<li>140°: Each anchor has 146% of the force applied to it</li>*/}
          {/*<li>150°: Each anchor has 193% of the force applied to it</li>*/}
          <li>170°: Each anchor has 574% of the force applied to it</li>
          {/*<li>*/}
          {/*  180° (impossible): Each anchor has infinite force applied to it*/}
          {/*</li>*/}
        </ul>
        <h2>Redundant</h2>
        <p>
          Redundancy in an anchor means that if one piece of the system fails,
          the entire system will not fail. In canyoneering this would mean that
          if a piece of webbing gets cut through on a rock or if a bolt pulls
          out then the rappeller will not fall.
        </p>
        {/* TODO: If I ever make redundant its own page I should talk about redundant-only-where-it-matters like at high abrasion points */}
        {/*<p>*/}
        {/*  Redundancy does <em>not</em> mean that every individual piece of the*/}
        {/*  anchor is completely redundant. It only applies to pieces of the*/}
        {/*  system that are likely to fail when shock loaded.*/}
        {/*</p>*/}
        {/*<p>*/}
        {/*  Places that must be redundant for an anchor to be considered*/}
        {/*  redundant:*/}
        {/*</p>*/}
        {/*<ul>*/}
        {/*  <li>Webbing (besides the master point)</li>*/}
        {/*  <li>Bolts</li>*/}
        {/*  <li>Tree that is less than 6", not alive, or not well rooted</li>*/}
        {/*  <li>Rock that is not big</li>*/}
        {/*  <li>Any marginal anchor</li>*/}
        {/*</ul>*/}
        {/*<p>*/}
        {/*  Places that do not necessarily need to be redundant for an anchor to*/}
        {/*  be considered redundant:*/}
        {/*</p>*/}
        {/*<ul>*/}
        {/*  <li>*/}
        {/*    <G>Rapide</G> because they are very strong*/}
        {/*  </li>*/}
        {/*  <li>*/}
        {/*    <G>Master point</G> but I don't know why exactly*/}
        {/*  </li>*/}
        {/*  <li>*/}
        {/*    Single solid natural anchor like a huge rock where if it fell you*/}
        {/*    would be crushed even if a separate anchor held*/}
        {/*  </li>*/}
        {/*</ul>*/}
        <h2>No Extension</h2>
        <p>
          This is the "N" and the "E". No extension means that if one of the
          anchors were to fail then the rappeller wouldn't fall any distance and
          the surviving anchor would not be shock loaded.
        </p>
        <p>
          A very small amount of extension (like an inch or two) is often
          considered acceptable if it means better equalization. Examples of
          anchor rigging that has <em>almost</em> no extension are the{' '}
          <G>equalette</G>, <G>quad</G>, and <G>redundant wrap</G>.
        </p>
        <h2>Solid</h2>
        <p>
          Solid simply means the anchor can easily sustain typical forces from
          rappelling and even shock loading without the possibility of failure.
        </p>
        <p>
          Sometimes I hear the word "Strong" in place of "Solid". They mean the
          same thing.
        </p>
        <h2>Timely</h2>
        <p>
          Timely means the anchor is evaluated and rigged in a timely manner. If
          it takes a long time to do this then it is inefficient, boring for the
          others, and they can lose confidence in the rigger's ability to rig.
        </p>
        <h2>How to tie earnest rigging onto two bolts</h2>
        <StyledCarousel
          images={[
            data.tieEarnest01,
            data.tieEarnest02,
            data.tieEarnest03,
            data.tieEarnest04,
            data.tieEarnest05,
            data.tieEarnest06,
            data.tieEarnest07,
          ]}
          captions={[
            'Put the webbing through the first bolt',
            'Put the webbing through the second bolt',
            'Tie a ring bend to make a loop',
            'Equalize the two lengths of webbing',
            'Pinch about 12 inches of webbing in the direction of the rappel so it will be equalized.',
            <>
              This length of pinched webbing is used to tie the master point.
              Tie a <G>water knot</G> with these four pinched strands
            </>,
            "Add the rapide and you've completed the EARNEST anchor!",
          ]}
        />
      </Layout>
    );
  }
}

export default withFrontMatter('earnest')(Canyoneering_Earnest);

export const query = graphql`
  query {
    tieEarnest01: file(
      relativePath: { eq: "images/canyoneering/earnest/tie-earnest-01.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tieEarnest02: file(
      relativePath: { eq: "images/canyoneering/earnest/tie-earnest-02.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tieEarnest03: file(
      relativePath: { eq: "images/canyoneering/earnest/tie-earnest-03.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tieEarnest04: file(
      relativePath: { eq: "images/canyoneering/earnest/tie-earnest-04.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tieEarnest05: file(
      relativePath: { eq: "images/canyoneering/earnest/tie-earnest-05.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tieEarnest06: file(
      relativePath: { eq: "images/canyoneering/earnest/tie-earnest-06.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tieEarnest07: file(
      relativePath: { eq: "images/canyoneering/earnest/tie-earnest-07.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
